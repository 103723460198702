import React from 'react'
import axios from 'axios'
import {ServerUrl} from './include'



export const FormatNumber = (num)=>{
    
  let newNumber = Number(num)
  return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const generateCardNo=(t)=>{
  t||(t=16);
  for(var e="",a=0;a<t;a++){
    var n=Math.floor(Math.random()*"1234567890".length);
    e+="1234567890".substring(n,n+1)
    }
   
    return e
}

export  const longDate=(conv)=>{
  var options  = {year:'numeric', month:'long', day:'numeric'}
  var ndate = new Date(conv);
  return ndate.toLocaleDateString("en-US", options)
}
export const getMonthYear=()=>{
  var months = ['January', 'February', 'March', 
               'April', 'May', 'June', 'July', 
               'August', 'September', 'October', 'November', 'December'];
  var date = new Date()
 var nDate =  months[date.getMonth()] +' '+ date.getFullYear()
 return nDate
}

export const getMonth=()=>{
  var months = ['January', 'February', 'March', 
               'April', 'May', 'June', 'July', 
               'August', 'September', 'October', 'November', 'December'];
  var date = new Date()
 var nDate =  months[date.getMonth()]
 return nDate
}
 export const getTime =(value)=>{
  var timeString = String(value)
 var hourEnd = timeString.indexOf(":");
 var H = timeString.substr(0,2);
 var h = H % 12 || 12;
 var ampm =  (H<12 || H===24)?"AM":"PM";
  if (h < 10) h = "0" + h
 var result = h + timeString.substr(hourEnd, 3)+" "+ampm


return result;
}
export const sort=(a,b)=>{
  let result = 0;
  if(Number(a.displayOrder)>Number(b.displayOrder)){
      result = 1
  }else if (Number(a.displayOrder)<Number(b.displayOrder)){
      result = -1
  }
  return result
  }

  export  const compareSort=(a,b)=>{
    let result = 0;
    if(b.order >a.order){
        result = 1
    }else if (b.order <a.order){
        result = -1
    }
    return result
    }
export const compare=(a,b)=>{
  let result = 0;
  if(Number(a.order)>Number(b.order)){
      result = 1
  }else if (Number(a.order)<Number(b.order)){
      result = -1
  }
  return result
  }

  export const convertDateTime=(date)=>{
    let result = ''
    if(date!=='0000-00-00 00:00:00'){
   result = new Date(date).toISOString().slice(0,19)
    }else{
      result =''
    }
    return result
  }
export const convertDate=(date)=>{
  let result = ''
  if(date!=='0000-00-00'){
 result = new Date(date).toISOString().slice(0,10)
  }else{
    result =''
  }
  return result
}

export const getDays=(date)=>{
  let day;
  switch (new Date(date).getDay()){
      case 0:
          day ="Sunday";
          break;
      case 1:
          day ="Monday";
          break;
      case 2:
          day ="Tuesday";
         break;
      case 3:
          day ="Wednessday";
              break;
      case 4:
          day ="Thursday";
              break;
      case 5:
          day ="Friday";
          break;
      case 6:
          day ="Saturday"
          break;
      default:
          day="Sunday"
  }
      return day
  }
export  const getDateDifference=(from, to)=>{
  var fromDate =  new Date(from);
  var toDate =  new Date(to);
  var diff_time = toDate.getTime()-fromDate.getTime()
  var differ = diff_time/(1000*3600*24); 
  return differ
}

export  const CheckExistence =(table, column, data)=>{
  
  if(data===''){
    return false
  }else{
   let res ='';
  let url = ServerUrl + '/function/checkExistence/'+table+'/'+column+'/'+data;
  axios.get(url).then(response=>{
    res = response.data})
    return res;
}

}


export const toWords = (n = 0) => {

  const Ones  = ["","One","Two","Three","Four","Five","Six","Seven","Eight","Nine","Ten",
  "Eleven","Twelve","Thirteen","Fourteen","Fifteen","Sixteen","Seventeen","Eighteen","Nineteen"],
Tens  = ["","","Twenty","Thirty","Forty","Fifty","Sixty","Seventy","Eighty","Ninety","Hundred"],
Scale = ["","Thousand","Million","Billion","Trillion","Quadrillion","Quintillion","Sextillion"];
//==================================


  if (n == 0) return "Zero";  
      // check for zero
  n = ("0".repeat(2*(n+="").length % 3) + n).match(/.{3}/g);   // create triplets array
  
  if (n.length > Scale.length) return "Too Large";             // check if larger than scale array
  
  let out= ""; 
  return n.forEach((Triplet,pos) => {   
              // loop into array for each triplet
  if (+Triplet) { 
    out+=' ' +(+Triplet[0] ? Ones[+Triplet[0]]+' '+ Tens[10] : "") +
  ' ' + (+Triplet.substr(1)< 20 ? Ones[+Triplet.substr(1)] :
  Tens[+Triplet[1]] + (+Triplet[2] ? "-" : "") + Ones[+Triplet[2]]) +
  ' ' +  Scale[n.length-pos-1];
   }
  }), out.replace(/\s+/g,' ').trim();
  
  }; 

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span style={{color:'black', fontSize:'15px',fontWeight: 'bold', }}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export function numberToEnglish( n ) {
        
  var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g,"");

  /* Is number zero? */
  if( parseInt( string ) === 0 ) {
      return 'zero';
  }
  
  /* Array of units as words */
  units = [ '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen' ];
  
  /* Array of tens as words */
  tens = [ '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety' ];
  
  /* Array of scales as words */
  scales = [ '', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion', 'Sextillion', 'Septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion' ];
  
  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while( start > 0 ) {
      end = start;
      chunks.push( string.slice( ( start = Math.max( 0, start - 3 ) ), end ) );
  }
  
  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if( chunksLen > scales.length ) {
      return '';
  }
  
  /* Stringify each integer in each chunk */
  words = [];
  for( i = 0; i < chunksLen; i++ ) {
      
      chunk = parseInt( chunks[i] );
      
      if( chunk ) {
          
          /* Split chunk into array of individual integers */
          ints = chunks[i].split( '' ).reverse().map( parseFloat );
      
          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if( ints[1] === 1 ) {
              ints[0] += 10;
          }
          
          /* Add scale word if chunk is not zero and array item exists */
          if( ( word = scales[i] ) ) {
              words.push( word );
          }
          
          /* Add unit word if array item exists */
          if( ( word = units[ ints[0] ] ) ) {
              words.push( word );
          }
          
          /* Add tens word if array item exists */
          if( ( word = tens[ ints[1] ] ) ) {
              words.push( word );
          }
          
          /* Add 'and' string after units or tens integer if: */
          if( ints[0] || ints[1] ) {
              
              /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
              if( ints[2] || ! i && chunksLen ) {
                  words.push( and );
              }
          
          }
          
          /* Add hundreds word if array item exists */
          if( ( word = units[ ints[2] ] ) ) {
              words.push( word + ' hundred' );
          }
          
      }
      
  }
  
  return words.reverse().join( ' ' );
  
}
