import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl,  config, Usertype, staffCode, PictureUrl} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';


import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const UploadCheque =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [errors, setErrors] = useState({});
         
    const [content, setContent] = useState([])

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       

    
        var sql ="SELECT q.id,  c.fullname, c.email, q.code, q.customer_code, q.fullname as cheque_name, q.telephone, q.bank_account, q.image_url, q.status, q.created_at from tbl_cheque q, tbl_customers c where c.created_by ='"+staffCode+"' and c.code = q.customer_code order by q.id DESC " ;
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)

            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleDelete =(e, code)=>{
    e.preventDefault()
  let close =   document.getElementById('btnWarningDialog-'+code)
  close.click();
  
  setNotice({...notice, 
      isLoading: true}) 

  const fd = new FormData();
  fd.append('ID', 'code')
  fd.append('data',code)
  //fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_cheque';
    axios.post(url, fd, config)
    .then(response =>{
     if(response.data.type ==='success'){
        Alerts('Saved!', 'success', response.data.message)
      
            } else{
        Alerts('Error!', 'danger', response.data)
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        fetchContent()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
  
  
}

const tableHeader = [
    {dataField: 'image_url', text: 'Cheque', editable: false, formatter:(cell, row)=><img id={cell} height="60" src={`${PictureUrl}/cheque/${cell}`} title={row.fullname}  />},


    {dataField: 'fullname', text: 'Customer', editable:false, formatter:(cell, row)=><span>{cell}</span>},

    

{dataField: 'cheque_name', text: 'Account Name', editable: false},

{dataField: 'bank_account', text: 'Account Number', editable: false},
{dataField: 'telephone', text: 'Telephone', editable: false},


 {dataField: 'created_at', text: 'Date Added', editable: false},

 {dataField: 'status', text: 'Status', sort: true, editor: {
    type: Type.SELECT, options:[{value:'Pending', label:'Pending'},
    {value:'Completed', label:'Completed'}]
  }, formatter:(cell)=>cell==='Pending'? 
  <span className="badge bg-danger badge-pill">{cell}</span>: 
  <span className="badge bg-success badge-pill">{cell}</span>},
{dataField: 'ID',  text: 'Action',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter:(cell, row)=><div>
	
<WarningModal  handleAction={(e)=>handleDelete(e, row.code)} mID={row.code} />

<button type="button" className="btn btn-warning btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icon-trash-alt"></i> Delete</button></div>}

 ];

 

									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_cheque'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             
                             </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.id);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
   },[]);

    return (<Template 
    name=' Cheques' 
    title='Customer Uploaded Cheque' 
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}



                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All Cheque</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>

</Template>
);
       
}

export default React.memo(UploadCheque)  
