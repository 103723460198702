import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning} from '../components/notify'
import {ServerUrl,  config,  Token, staffCode} from '../components/include'
import axios from 'axios'
import { Loader } from '../components/loader';



const Settings =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

   
	const [settings, setSettings] = useState([])
    const [errors, setErrors] = useState({});

    const fetchSettings =()=>{
       
        var sql ="Select * from tbl_settings WHERE user_code = '"+staffCode+"'";
        const fd = new FormData();
        fd.append("sql", sql);
       fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        axios.post(url, fd, config).then(result=>{  
            if(Array.isArray(result.data)){

                setSettings(result.data[0])
            }
            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        })

  }
    



    const handleChange = event =>{		
        let {name, value} = event.target;	
       setSettings({...settings, [name] : value });
       setErrors({...errors, [name]:''});
    }

    
	

 function submit(){  
                setNotice({...notice,  isLoading: true})     
              
            let formData = settings;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
          
            let url = ServerUrl+'/update_controller.php?tablename=tbl_settings';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){
                Alerts('Success!', 'success', response.data.message)
                      } else{
                 
                    window.scrollTo(0,0)
              setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                      }   
                  })
                  .catch((error)=>{
                    setErrors({...errors, errorMessage: error.message})
                  }).finally(()=>{
                    setNotice({...notice,  isLoading: false}) 
                  })
              }

             
 useEffect(()=>{
    fetchSettings()
   },[]);

    return (<Template 
    name='Settings' 
    title='Use the form below to enable and disbaled tokens' 
    menu='Settings'
    menuLink='/settings'
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}

                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Create an Account</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						<form action="#">
							<div className="row">

										<legend className="font-weight-semibold"><i className="icon-reading mr-2"></i> Personal details</legend>
								<div className="col-md-6">
									<fieldset>

       

        <div className="form-group">
        <label>Authorization Code:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isAuthorize==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isAuthorize" onChange={handleChange} value='true'  /></span></div>
                        Active
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isAuthorize==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isAuthorize" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    Inactive
            </label>
        </div>
    
    </div>
    

        


    <div className="form-group">
        <label>Tax Pin:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTaxPin==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isTaxPin" onChange={handleChange} value='true'  /></span></div>
                        Active
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTaxPin==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isTaxPin" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    Inactive
            </label>
        </div>
    
    </div>




    <div className="form-group">
        <label>Transfer Access Code:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTaCode==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isTaCode" onChange={handleChange} value='true'  /></span></div>
                        Active
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTaCode==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isTaCode" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    Inactive
            </label>
        </div>
    
    </div>



    <div className="form-group">
        <label>Email Transaction</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.emailAccount==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="emailAccount" onChange={handleChange} value='true'  /></span></div>
                       Yes
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.emailAccount==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="emailAccount" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    No
            </label>
        </div>
    
    </div>
            </fieldset>
            </div>
          
            <div className="col-md-6">
									<fieldset>



                                    

                                    <div className="form-group">
        <label>Federal Regulation Code:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isFrCode==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isFrCode" onChange={handleChange} value='true'  /></span></div>
                        Active
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isFrCode==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isFrCode" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    Inactive
            </label>
        </div>
    
    </div>



    <div className="form-group">
        <label>Insurance Code:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isInsuranceCode==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isInsuranceCode" onChange={handleChange} value='true'  /></span></div>
                        Active
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isInsuranceCode==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isInsuranceCode" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    Inactive
            </label>
        </div>
    
    </div>




    <div className="form-group">
        <label>Place Transfer on Hold</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTransferOld==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="isTransferOld" onChange={handleChange} value='true'  /></span></div>
                       Yes
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.isTransferOld==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="isTransferOld" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    No
            </label>
        </div>
    
    </div>







    <div className="form-group">
        <label>Login 2FA</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.client2Fa==='true'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="client2Fa" onChange={handleChange} value='true'  /></span></div>
                       Yes
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={settings.client2Fa==='false'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="client2Fa" onChange={handleChange} value='false'  />
                    
                    </span></div>
                    No
            </label>
        </div>
    
    </div>


									</fieldset>
								</div>
							</div>

							<div className="text-center">
								<button type="button" id="submitForm" onClick={submit} className="btn btn-primary"><i className="icon-paperplane ml-2"></i> Save Settings </button>
							</div>
						</form>
					</div>
				</div>




</Template>
);
       
}

export default React.memo(Settings)  
