import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl, staffCode, config,  PictureUrl, Usertype} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';


import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormatNumber } from '../components/globalFunction';

const Users =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [errors, setErrors] = useState({});
         
    const [content, setContent] = useState([])

    const [user, setUser]=  useState({
        email:'',
        password:'',
        fullname:'',
        usertype:'Admin',
        isAuthorize:true,
        isTaxPin:true,
        isTaCode:false,
        isInsuranceCode:true,
        isFrCode:true,
        isTransferOld:true,
        client2Fa:true,
        emailAccount:true,
        created_by:staffCode
    })

    const handleSubmit =(event)=>{
        event.preventDefault();
        
        let error = {...errors}; 
        let formIsValid = true;

	 let msg ='This field is required';
	 
     if(!user.fullname){
        error.fullname =msg;
        formIsValid = false;
    } 

    if(!user.usertype){
      error.usertype =msg;
      formIsValid = false;
  } 

  if(!user.password){
    error.password =msg;
    formIsValid = false;
} 

if(!user.email){
    error.email =msg;
    formIsValid = false;
} 


    if(!formIsValid){
	setErrors(error) 
    }



    if(formIsValid) {
        setNotice({...notice, 
            isLoading: true})     
        
      let formData = user;
      const fd = new FormData();
      for(let k in formData){
      fd.append(k, formData[k])
      }
      
      let url = ServerUrl+'/save_controller.php?tablename=tbl_users';
      axios.post(url, fd, config)
      .then(response =>{
      if(response.data.type === 'success'){
          Alerts('Success!', 'success', response.data.message)
                } else{
           
              window.scrollTo(0,0)
        setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                }   
            })
            .catch((error)=>{
              setErrors({...errors, errorMessage: error.message})
            }).finally(()=>{
             
              setUser({
                email:'',
                password:'',
                fullname:'',
                usertype:''
              })
              setNotice({...notice, 
                isLoading: false
              })
            }) 
     }

    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_user order by ID DESC " ;

       
          
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)

            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            fetchContent()
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  
  const handleDelete =(e, row)=>{
      e.preventDefault()
    let close =   document.getElementById('btnWarningDialog-'+row.ID)
    close.click();
    
    setNotice({...notice, 
        isLoading: true}) 

    const fd = new FormData();
    fd.append('ID', 'code')
    fd.append('data',row.code)
    //fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_user';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'success', response.data.message)
        
              } else{
          Alerts('Error!', 'danger', response.data)
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          fetchContent()
          setNotice({...notice, 
              isLoading: false
          })
      }) 
    
    
}

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setUser({...user, [name] : value });
    setErrors({...errors, [name] : ''});
}


const tableHeader = [
  

{dataField: 'fullname', text: 'Fullname'},
{dataField: 'email', text: 'Email'},
{dataField: 'usertype', text: 'Type', editable:false},
{dataField: 'password', text: 'Password'},


{dataField: 'status', text: 'Status', sort: true, editor: {
    type: Type.SELECT, options:[
        {value:'Active', label:'Active'},
    {value:'Inactive', label:'Inactive'}]

  }, formatter:(cell)=>cell==='Active'? 
  <span className="badge bg-success badge-pill">{cell}</span>: 
  <span className="badge bg-danger badge-pill">{cell}</span>}
 ];

 


									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_user'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />          

                             </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
   },[]);

    return (<Template 
    name='Users' 
    title='All registered users' 
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}


<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Add New Users </h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						
<form onSubmit={handleSubmit}>
                    <div className="row">
								<div className="col-md-6">
									<fieldset>
										

        <div className="form-group">
            <label>User Type:</label>
            <select className="form-control" value={user.usertype} name="usertype" onChange={handleChange}>
                
            <option value="Admin">Admin</option>
                <option value="Super Admin">Super Admin</option>
            </select>

        </div>

        <div className="form-group">
            <label>Fullname:</label>
            <input type="text" required  name="fullname" value={user.fullname} placeholder="Full Name" onChange={handleChange} className='form-control' /> 
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.fullname}</label>
        </div>


        </fieldset>
                </div>

    <div className="col-md-6">
        <fieldset>


        <div className="form-group">
            <label>Email:</label>
            <input type="email" required  name="email" value={user.email} placeholder="Email Address" onChange={handleChange} className='form-control' /> 
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.email}</label>
        </div>

        <div className="form-group">
                <label>Account Password:</label>
                <input type="password" required  name="password" value={user.password} placeholder="Password" onChange={handleChange} className='form-control' /> 

                <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.password}</label>
            </div>

        </fieldset>
                </div>
                
                </div>

                <div className="row"> 
            
            <div className="col-md-12 text-right">
           
	<button type="submit" className="btn btn-success "  ><i className="icon-user"></i> Create Account</button>
    </div>
            
            </div>

            </form>

					</div>
				</div>


                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All Users</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>




</Template>
);
       
}

export default React.memo(Users)  
