import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import { FormatNumber, generateCardNo, numberToEnglish} from '../components/globalFunction';
import html2canvas from 'html2canvas'
const Cheque =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [errors, setErrors] = useState({});

	const [items, setItems] = useState({
        fullname:'',
        amount:'',
        card_date:'',
        amount_word:'',
        amount_no:'',
        long_date:'',
        display:false
    })


    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='amount'){
            value = value.replace(/[^0-9]/g, "")
          }

       setItems({...items, [name] : value, display:false });
       setErrors({...errors, [name]:''});
    }


    const takeScreenshot =()=>{
        const element=  document.getElementById('wrapper');
          html2canvas(element, {allowTaint: true, useCORS: true, logging: true}).then((canvas) => {
            const base64image = canvas.toDataURL("image/png", 1.0);
          let a = document.createElement('a');
            a.href = base64image;
            a.download = '...';
            a.click(); 

        });
        } 


    const handleSubmit = event =>{
        event.preventDefault();

        let error = {...errors}; 
  let formIsValid = true;


if(!items.amount){
  error.amount = 'This field is compulsory'
  formIsValid = false;
}

if(!items.fullname){
    error.fullname = 'This field is compulsory'
    formIsValid = false;
  }

  if(!items.card_date){
    error.card_date = 'This field is compulsory'
    formIsValid = false;
  }

  setErrors(error)
  if(formIsValid) {
  
   setItems({
    ...items,

    cheque_no:'0'+generateCardNo(4),
    serial_no:generateCardNo(10),
    amount_word:numberToEnglish(items.amount),
    amount_no:FormatNumber(items.amount),
    long_date:(new Date(items.card_date).toISOString()).slice(0,10),
    display:true
   })

  }
    }


      
    return (<Template
    
        name='Cheque' 
    title='Use the form below to generate check' 
    menu='Settings'
    menuLink='/settings'
    >
<div className="row">
    <div className="col-md-4 card p-4">

    <fieldset>
				<legend className="font-weight-semibold"><i className="icon-reading mr-2"></i> Cheque Details</legend>


                                        <div className="form-group">
            <label>Amount <span style={{color:'red'}}>*</span> :</label>
            <input type="text"  name="amount" value={items.amount} placeholder="Enter Amount" onChange={handleChange} className={errors.amount  ? 'form-control border-danger' : 'form-control'} /> 
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.amount}</label>

        </div>


    <div className="form-group">
            <label>Cheque Date:</label>

        <input type="date"  name="card_date" className={errors.card_date  ? 'form-control border-danger' : 'form-control'}  value={items.card_date} placeholder="Select Date" onChange={handleChange}  /> 
        </div>
        <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.card_date}</label>


        <div className="form-group">
            <label>Customer fullname <span style={{color:'red'}}>*</span> :</label>
            <input type="text"  name="fullname" value={items.fullname} placeholder="Enter customer fullname" onChange={handleChange} className={errors.fullname  ? 'form-control border-danger' : 'form-control'} /> 
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.fullname}</label>

        </div>


        <div className="text-center">
			<button type="button" id="submitForm" onClick={handleSubmit} className="btn btn-primary"><i className="icon-paperplane ml-2"></i> Generate Cheque </button>
							</div>


    </fieldset>
    </div>
    {items.display?
    <div className="col-md-8">

  
<div className="check-wrapper" >
<div className="cheque" id='wrapper'>
    <div className="row-logo">
<img src="/assets/images/logo.png" alt="" className='check-logo' />
<span className='sno'>{items.cheque_no}</span>
</div>

 <label htmlFor="" className='c-date'>{items.long_date}</label>

 <div className="name-amount">

 <span>{items.fullname}</span>
 </div>

 <span className='sno amt'>{items.amount_no}</span>

<span className='sno amtw'>{items.amount_word}</span>


<div>
<img src="/assets/images/sign.png" alt=""  className='check-sign' />
</div>

<p className='sno m20'>{items.serial_no}</p>
 
</div>
</div>

<div className="text-center">
			<button type="button" id="submitForm" onClick={takeScreenshot} className="btn btn-success"><i className="icon-camera ml-2"></i> Download Cheque </button>
							</div>

</div>:[]}
</div>

</Template>
);
       
}

export default React.memo(Cheque)  
